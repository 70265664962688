import React, { useRef, useEffect, useState } from "react";
import { ContextProviderComponent } from "src/layouts/Context.js";
import styled from "styled-components";
import colors from "src/assets/styles/colors";
import { Helmet } from "react-helmet";
// import Nav from "src/components/global/Nav.js";
import GlobalStyle from "src/assets/styles/globalStyles.js";
// import Footer from "src/components/global/Footer";


const IndexPage = ({ children }) => {
  return (
    <>
      <ContextProviderComponent>
        <GlobalStyle />
        {/* <Nav /> */}

        {children}
        {/* <Footer /> */}
      </ContextProviderComponent>
    </>
  );
};

export default IndexPage;
